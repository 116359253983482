import React from "react";
import { Router } from "@reach/router";
import { connect } from "react-redux";
import Layout from "../components/Layout";
import Title from "../components/t";
import { graphql } from "gatsby";
import {
  DismissibleFullWidthAdBlue,
  FullWidthAdBlue,
} from "../components/AdComponent";

// import '../templates/movie.css'

const App = ({ user, token }) => (
  <Layout>
    {/* <DismissibleFullWidthAdBlue
      client="ca-pub-3093835180445554"
      slot="9087869955"
    /> */}

    <Router>
      <Title path="/t/:slug" user={user} token={token} />
      <Title path="/:lang/t/:slug" user={user} token={token} />
    </Router>
  </Layout>
);

const mapStateToProps = (state) => ({
  user: state.user.user,
  token: state.user.token,
});

export default connect(mapStateToProps)(App);

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
