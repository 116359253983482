import React from "react";
import PropTypes from "prop-types";
// import Observer from '@researchgate/react-intersection-observer'
import { Col, Row, Spinner } from "reactstrap";
// import styled from '@emotion/styled'

import { rateItem, fetchUserRating, fetchMovieTranslation } from "../api";
import {
  GENRES_TABLE_FR,
  CONTENT_TYPE_MOVIE_TRANSLATION,
  COUNTRIES_FR,
} from "../const";

import AdComponent, {
  AmazonAd,
  AdsenseHalfPage,
} from "../components/AdComponent";
import Metadata from "../components/Metadata";
// import RatingSection from '../components/RatingSection'
// import RelatedMovies from '../components/RelatedMovies'
// import Rows from '../components/Rows'
// import StarRatingComponent from 'react-star-rating-component'
import StarRatingPopover from "../components/StarRatingPopover";
import Video from "../components/Video";

// import "../templates/movie.css"
import MovieTitleBox from "../components/boxes/MovieTitleBox";
import MovieDetailsBox from "../components/boxes/MovieDetailsBox";
import MovieRatingBox from "../components/boxes/MovieRatingBox";
import MovieButtonsBox from "../components/boxes/MovieButtonsBox";
import MovieJumbotronBox from "../components/boxes/MovieJumbotronBox";
// import VideoUploader from '../components/VideoUploader';

import History from "../components/History";
import MovieRelatedBox from "./boxes/MovieRelatedBox";
// import HowToSeeBox from './boxes/HowToSeeBox'
import { trackCustomEvent } from "gatsby-plugin-google-analytics";
import {
  getI18nURLPrefix,
  getWatchlistFromLocalstorage,
} from "../utils/localStorage";
import { LogPageView } from "./LogEvents";
// import MovieRelatedVideoBox from './boxes/MovieRelatedVideoBox'
import { Disqus } from "gatsby-plugin-disqus";
import EpisodeSelectorBox from "./boxes/EpisodeSelectorBox";
import AffiliateProductRelatedBox from "./boxes/AffiliateProductRelatedBox";
import { withTranslation } from "react-i18next";
// import AdComponent from '../components/AdComponent'

const Rating = ({
  avg_rating,
  runtime,
  release,
  genres,
  ratingNumber,
  token,
  handleStarClick,
  handleStarHover,
  handleFormSubmit,
  rating,
}) => {
  // const releaseYear = new Date(release).getFullYear()
  // const genreNames = !genres ? '' : genres.map(i => `${GENRES_TABLE_FR[i]}, `)
  // genreNames[genreNames.length-1] = genreNames[genreNames.length-1].slice(0, -2)

  return (
    <div>
      <div
        style={{ fontSize: 24 }}
        className="d-flex align-items-center flex-wrap"
      >
        {/* <StarRatingComponent 
          name="rating" 
          editing={false}
          starCount={5}
          value={avg_rating}
        /> */}
        {/* <span style={{fontSize: 15, marginBottom: '0.5em', marginLeft: 5}}>({ratingNumber})</span>
        <span style={{fontSize: 15, marginBottom: '0.5em', marginLeft: 5}}>({avg_rating}/5)</span>
        <span style={{fontSize: 15, marginBottom: '0.5em', marginLeft: 5}} className="font-italic"> {runtime}mn </span>
        <span style={{fontSize: 15, marginBottom: '0.5em', marginLeft: 5}} className="font-italic"> {releaseYear} </span>
        <span style={{fontSize: 15, marginBottom: '0.5em', marginLeft: 5}} className=""> {genreNames} </span> */}

        <StarRatingPopover
          handleStarClick={handleStarClick}
          handleStarHover={handleStarHover}
          handleFormSubmit={handleFormSubmit}
          rating={rating}
          token={token}
        >
          {/* <span className="btn btn-link btn-sm pl-0">Noter </span> */}
          <button class="btn btn-dark my-2 mr-2">
            {rating === 1 && (
              <span>
                <span className="text-warning">★</span>★★★★
              </span>
            )}
            {rating === 2 && (
              <span>
                <span className="text-warning">★★</span>★★★
              </span>
            )}
            {rating === 3 && (
              <span>
                <span className="text-warning">★★★</span>★★
              </span>
            )}
            {rating === 4 && (
              <span>
                <span className="text-warning">★★★★</span>★
              </span>
            )}
            {rating === 5 && (
              <span>
                <span className="text-warning">★★★★★</span>{" "}
              </span>
            )}
            {/* {rating && <span className="text-warning">★</span>}  */}

            {!rating && "★ Noter"}
          </button>
          {/* <StarRatingComponent 
              name="userRating" 
              editing={false}
              starCount={1}
              value={rating}
            /> */}
          {/* <span style={{fontSize: 15}}>(<i className={ !rating && 'text-dark' || rating < 3 && 'text-danger' || rating < 5 && 'text-success' || 'text-warning'}>{rating}/5</i>)</span> */}
        </StarRatingPopover>
      </div>
    </div>
  );
};

// const getCurrentUserRating = (user, ratings) => {
//   if (!user || !ratings) return null
//   let currUserRating = null
//   ratings.forEach(i => {
//     if (user.username === i.username){
//       currUserRating = i
//     }
//   })
//   return currUserRating
// }

class MovieTemplate extends React.Component {
  static propTypes = {
    user: PropTypes.object,
    token: PropTypes.string,
    slug: PropTypes.string,
    lang: PropTypes.string,
  };

  constructor(props) {
    super(props);
    this.state = {
      movie: null,
      movieTranslation: null,
      rating: null,
      ratingHeadline: null,
      ratingContent: null,
      recommendationNumber: 10,
      selectedVideo: null,
      isLoadingVideo: false,
      prefix: `/`,
      currUserRating: {
        headline: null,
        content: null,
        rating: null,
        id: null,
        username: null,
      },
      watchlist: [],
    };
    this.handleFormSubmit = this.handleFormSubmit.bind(this);
    this.getCurrentUserRating = this.getCurrentUserRating.bind(this);
  }

  UNSAFE_componentWillMount() {
    if (typeof window !== "undefined") {
      require("intersection-observer");
    }
  }

  resetState() {
    this.setState({
      movie: null,
      movieTranslation: null,
      rating: null,
      ratingHeadline: null,
      ratingContent: null,
      recommendationNumber: 10,
      selectedVideo: null,
      isLoadingVideo: false,
      currUserRating: {
        headline: null,
        content: null,
        rating: null,
        id: null,
        username: null,
      },
    });
  }

  async fetchMovieData() {
    let { slug, lang } = this.props;

    if (!lang) {
      lang = "fr";
    }

    const { movie, movieTranslation } = await fetchMovieTranslation(slug, lang);
    this.setState({ movie, movieTranslation });

    if (movieTranslation.currUserRating) {
      const { currUserRating } = movieTranslation;
      this.setState({
        rating: currUserRating.rating,
        ratingHeadline: currUserRating.headline,
        ratingContent: currUserRating.content,
        currUserRating: currUserRating,
      });
    }
  }

  async componentDidMount() {
    try {
      this.fetchMovieData();
      const watchlist = await getWatchlistFromLocalstorage();
      const prefix = await getI18nURLPrefix();
      this.setState({ watchlist, prefix });
    } catch (err) {
      console.log(err);
    }
  }

  async componentDidUpdate(prevProps) {
    if (this.props.slug !== prevProps.slug) {
      this.resetState();
      this.fetchMovieData();
    }
  }

  async getCurrentUserRating(user) {
    if (!user) return null;
    const { token } = this.props;
    const currUserRating = await fetchUserRating(
      this.state.movieTranslation.id,
      token
    );

    return currUserRating;
  }

  async handleFormSubmit() {
    const { user, token } = this.props;
    const { id } = this.state.movieTranslation;
    let method;
    let rating = {
      content_type: CONTENT_TYPE_MOVIE_TRANSLATION,
      user: user.pk,
      object_id: id,
      rating: this.state.rating,
      headline: this.state.ratingHeadline || null,
      content: this.state.ratingContent || null,
    };
    if (this.state.currUserRating.id) {
      const { id } = this.state.currUserRating;
      method = "PUT";
      rating["pk"] = id;
      rating["headline"] =
        this.state.currUserRating.headline || this.state.ratingHeadline || null;
      rating["content"] =
        this.state.currUserRating.content || this.state.ratingContent || null;
    } else {
      method = "POST";
    }

    trackCustomEvent({
      category: "Rating",
      action: "Rating Attempt",
      label: `Attempt to send this rating: ${this.state.rating}----${
        this.state.currUserRating.headline || this.state.ratingHeadline || null
      }----${
        this.state.currUserRating.content || this.state.ratingContent || null
      }`,
    });

    const result = await rateItem(rating, token, method);

    // Update local state on success
    if (result.rating && this.state.currUserRating.id) {
      this.setState({
        currUserRating: {
          ...this.state.currUserRating,
          rating: result.rating,
        },
      });
      trackCustomEvent({
        category: "Rating",
        action: "Rating Success",
        label: `Succesfully sent this rating: ${this.state.rating}----${
          this.state.currUserRating.headline ||
          this.state.ratingHeadline ||
          null
        }----${
          this.state.currUserRating.content || this.state.ratingContent || null
        }`,
      });
    } else if (!this.state.currUserRating.id) {
      // If there is no rating and we rate it, completely fill the state
      this.setState({ currUserRating: { ...result } });
    }
  }

  handleStarClick = (nextValue, prevValue, name) => {
    this.setState({ rating: nextValue, starClicked: true });
  };

  handleStarHover = (nextValue, prevValue, name) => {
    if (!this.state.starClicked) {
      this.setState({ rating: nextValue });
    }
  };

  handleNextRecommendationsLoad = async (event, isIntersecting) => {
    const { recommendationNumber, movieTranslation } = this.state;
    const maxRecommendations = movieTranslation.recommendations.length;
    if (
      isIntersecting &&
      recommendationNumber < maxRecommendations &&
      !this.state.isRecommendationLoading &&
      event.intersectionRatio === 1
    ) {
      await this.setState({
        isRecommendationLoading: true,
        recommendationNumber: recommendationNumber + 10,
      });
      await this.setState({
        isRecommendationLoading: false,
      });
    }
  };

  handleRatingUpdateCallback = (resultUserRating) => {
    const currUserRating = this.state.currUserRating
      ? this.state.currUserRating
      : {};
    currUserRating.headline = resultUserRating.headline;
    currUserRating.content = resultUserRating.content;
    currUserRating.rating = resultUserRating.rating;

    // In case it is a POST (the first rating of his user on this movie)
    if (!currUserRating.id || !currUserRating.username) {
      currUserRating.id = resultUserRating.id;
      currUserRating.username = resultUserRating.username;
    }

    this.setState({
      currUserRating: currUserRating,
      rating: currUserRating.rating,
    });
  };

  handleSelectVideo = async (video) => {
    if (!video || !video.url) return;
    await this.setState({ isLoadingVideo: true });
    await this.setState({ selectedVideo: video });
    await this.setState({ isLoadingVideo: false });
  };

  render() {
    if (!this.state.movie) {
      // Show something, make them wait
      return (
        <>
          <div className="container-fluid movie-page-container p-0">
            <MovieJumbotronBox poster={null} video={null} title={null} />
            <Row className="m-auto text-center" style={{ maxWidth: 1200 }}>
              <Spinner className="m-auto text-center" size="sm" />
            </Row>
          </div>
        </>
      );
    }

    const { movie, movieTranslation, watchlist, prefix } = this.state;

    const { user, token, t } = this.props;

    // const allVideos = videos ? <Video videos={videos}/> : ''
    const firstMovie =
      movieTranslation.movies.length > 0 ? (
        <Video videos={movieTranslation.movies} />
      ) : null;
    const firstTrailer =
      movieTranslation.trailers.length > 0 ? (
        <Video videos={movieTranslation.trailers} />
      ) : null;

    let video = null;

    if (this.state.selectedVideo) {
      video = <Video videos={[this.state.selectedVideo]} />;
    } else {
      video = movieTranslation.movies.length > 0 ? firstMovie : firstTrailer;
    }

    const currUserRating = this.state.currUserRating;

    let genreNames = [];
    if (!movie.genres === false && movie.genres.length !== 0) {
      genreNames = movie.genres.map((i) => `${GENRES_TABLE_FR[i]}, `);
      genreNames[genreNames.length - 1] = genreNames[
        genreNames.length - 1
      ].slice(0, -2);
    }

    let countryNames = [];
    if (
      !movie.originalCountry === false &&
      movie.originalCountry.length !== 0
    ) {
      countryNames = !movie.originalCountry
        ? ""
        : movie.originalCountry.map((i) => COUNTRIES_FR[i] + ", ");
      countryNames[countryNames.length - 1] = countryNames[
        countryNames.length - 1
      ].slice(0, -2);
    }
    const date = new Date(movie.release).getFullYear();

    const isMobile = window.innerWidth <= 992;

    // Dynamically update avg rating if less than 10 ratings
    let updatedAvgRating =
      movieTranslation.ratings.length < 10 &&
      currUserRating &&
      currUserRating.rating
        ? (movieTranslation.avgRating * movieTranslation.ratings.length +
            currUserRating.rating) /
          (movieTranslation.ratings.length + 1)
        : movieTranslation.avgRating;

    let ratingNumber =
      currUserRating && currUserRating.rating
        ? movieTranslation.ratings.length + 1
        : movieTranslation.ratings.length;

    const ratingComponent = (
      <Rating
        // runtime={runtime}
        // release={release}
        // genres={genres}
        // avg_rating={avg_rating}
        // ratingNumber={ratings.length}
        token={token}
        rating={this.state.rating}
        handleStarClick={this.handleStarClick}
        handleStarHover={this.handleStarHover}
        handleFormSubmit={this.handleFormSubmit}
      />
    );

    const releaseYear = movie.release
      ? `(${new Date(movie.release).getFullYear()})`
      : "";
    const streaming = movieTranslation.movies.length > 0 ? " (streaming)" : "";
    const titlePrefix =
      movieTranslation.movies.length > 0 ? `${t("Regarder")} ` : "";
    const serpTitle = `${titlePrefix}${movieTranslation.title} ${releaseYear}${streaming}`;

    // Voir [title], [Films | Dessin Animé | Documentaire] complet en streaming: [synopsis]
    const beforeTitle = t("Voir");
    const afterTitle = t("complet en streaming");
    let mediaType = "";
    if (movie.genres.includes("3")) {
      mediaType = t("Dessin Animé");
    } else if (movie.genres.includes("7")) {
      mediaType = t("Documentaire");
    } else {
      mediaType = t("Film");
    }

    const serpDescription =
      streaming === ""
        ? movieTranslation.synopsis
        : `${beforeTitle} ${movieTranslation.title}, ${mediaType} ${afterTitle}: ${movieTranslation.synopsis}`;

    return (
      <>
        <Metadata
          type="movie"
          pathname={movieTranslation.slug}
          title={serpTitle}
          description={serpDescription}
          duration={movie.runtime}
          poster={movieTranslation.poster || null}
          noindex={movieTranslation.movies.length === 0}
          date={movieTranslation.created || null}
        />
        <div className="container-fluid movie-page-container p-0">
          {
            // Math.random() < 0.50 &&
            //   <div className="text-center pt-3">
            //     <iframe className="d-none d-md-flex d-lg-none m-auto" src="https://rcm-eu.amazon-adsystem.com/e/cm?o=8&p=48&l=ur1&category=prime_video&banner=1WPM16T3GQNXXGR11A02&f=ifr&linkID=fc5e64c4bfe268b6de1be4676429a56b&t=cinetimes0f-21&tracking_id=cinetimes0f-21" width="728" height="90" scrolling="no" border="0" marginwidth="0" style={{border:"none"}}  frameborder="0"></iframe>
            //     <iframe className="d-none d-sm-flex d-md-none m-auto" src="https://rcm-eu.amazon-adsystem.com/e/cm?o=8&p=26&l=ur1&category=prime_video&banner=178NVA4EJZGQWMBF67R2&f=ifr&linkID=e1c3761e4a25e8f110eb494385ea66a8&t=cinetimes0f-21&tracking_id=cinetimes0f-21" width="468" height="60" scrolling="no" border="0" marginwidth="0" style={{border:"none"}}  frameborder="0"></iframe>
            //     <iframe className="d-flex d-sm-none m-auto"  src="https://rcm-eu.amazon-adsystem.com/e/cm?o=8&p=288&l=ur1&category=prime_video&banner=1MMB6DFN40NE65X5R3R2&f=ifr&linkID=0e0bd4fee9c649ebb54e201bd4668d03&t=cinetimes0f-21&tracking_id=cinetimes0f-21" width="320" height="50" scrolling="no" border="0" marginwidth="0" style={{border:"none"}}  frameborder="0"></iframe>
            //     {/* <iframe className="d-flex d-sm-none m-auto"  src="https://rcm-eu.amazon-adsystem.com/e/cm?o=8&p=12&l=ur1&category=prime_video&banner=1AMG5VP0PM1EHJKZG002&f=ifr&linkID=606b9a4ae2b28eb7888b2c68f64296bf&t=cinetimes0f-21&tracking_id=cinetimes0f-21" width="300" height="250" scrolling="no" border="0" marginwidth="0" style={{border:"none"}}  frameborder="0"></iframe> */}
            //   </div>
          }

          {!this.state.isLoadingVideo && (
            <MovieJumbotronBox
              poster={movieTranslation.posterThumbX450}
              video={video}
              title={movieTranslation.title}
              fallback={
                <MovieTitleBox
                  title={movieTranslation.title}
                  synopsis={movieTranslation.synopsis}
                  slug={movieTranslation.slug}
                  avg_rating={updatedAvgRating}
                  ratingNumber={ratingNumber}
                  user={user}
                  tags={movieTranslation.tags}
                />
              }
            />
          )}

          {/* <Row className="mb-3 mt-3 mx-auto p-3 d-flex bg-white" style={{maxWidth: 1200}}>
          <Col className="bg-white text-center my-auto p-4 d-none d-md-block" md="3">
            <img title={movieTranslation.title && movieTranslation.title}  className="w-100 m-auto p mr-0 " src={movieTranslation.posterThumbX450 && movieTranslation.posterThumbX450.url} alt={movieTranslation.title && movieTranslation.title}/> 
          </Col>
          <Col className="w-100 align-self-center p-0 bg-white" md="9">
            <MovieTitleBox 
                title={movieTranslation.title}
                synopsis={movieTranslation.synopsis}
                slug={movieTranslation.slug}
                avg_rating={updatedAvgRating}
                ratingNumber={ratingNumber}
                user={user}
                tags={movieTranslation.tags}
                moviePk={movieTranslation.movies.length > 0 ? movieTranslation.movies[0].id : null}
                trailerPk={movieTranslation.trailers.length > 0 ? movieTranslation.trailers[0].id : null}
                episodes={movieTranslation.movies}
                prefix={prefix}
              />
          </Col>
        </Row> */}

          <Row className="m-auto" style={{ maxWidth: 1200 }}>
            <Col lg="8" className="px-0 px-md-3">
              {/* <EpisodeSelectorBox selectedVideoTitle={this.state.selectedVideo ? this.state.selectedVideo.title : null} handleSelectVideo={this.handleSelectVideo} episodes={movieTranslation.movies}/> */}

              {/* <MovieRelatedVideoBox translationId={movieTranslation.id} /> */}

              {isMobile && (
                <MovieButtonsBox
                  inWatchlist={movieTranslation.inWatchlist}
                  watchlist={watchlist}
                  movieTranslation={movieTranslation}
                  // firstMovie={movieTranslation.movies.length > 0 ? movieTranslation.movies[0] : null}
                  slug={movieTranslation.slug}
                  title={movieTranslation.title}
                  rate={ratingComponent}
                  movie={movie}
                  episodes={movieTranslation.movies}
                />
              )}

              {/* <MovieTitleBox 
                title={movieTranslation.title}
                synopsis={movieTranslation.synopsis}
                slug={movieTranslation.slug}
                avg_rating={updatedAvgRating}
                ratingNumber={ratingNumber}
                user={user}
                tags={movieTranslation.tags}
              /> */}
              <MovieTitleBox
                title={movieTranslation.title}
                synopsis={movieTranslation.synopsis}
                slug={movieTranslation.slug}
                avg_rating={updatedAvgRating}
                ratingNumber={ratingNumber}
                user={user}
                tags={movieTranslation.tags}
                // moviePk={movieTranslation.movies.length > 0 ? movieTranslation.movies[0].id : null}
                // trailerPk={movieTranslation.trailers.length > 0 ? movieTranslation.trailers[0].id : null}
                moviePk={null}
                trailerPk={null}
                episodes={movieTranslation.movies}
                prefix={prefix}
              />

              <MovieRelatedBox
                className="mt-3"
                translationId={movieTranslation.id}
                translations={movieTranslation.related_also_watched}
                title={t("Les autres ont également regardé")}
              />

              {
                isMobile ? (
                  <AdComponent
                    client="ca-pub-3093835180445554"
                    slot="1736611782"
                    customClasses="mb-3"
                    format="horizontal"
                  />
                ) : (
                  <></>
                )
                //  This ad has potential but it is too much
                //  (
                //     <div className="m-auto text-center" style={{maxWidth: 1200}}>
                //       <AdComponent client="ca-pub-3093835180445554" slot="5895348641" customClasses="mt-3" className="d-none" format="horizontal"/>
                //     </div>
                // )
              }

              {/* <AffiliateProductRelatedBox products={movieTranslation.products_related_by_tags} slug={movieTranslation.slug} translationId={movieTranslation.id}/> */}

              {/* <HowToSeeBox watchOn={movieTranslation.watchOn} movies={movieTranslation.movies}/> */}

              <MovieRatingBox
                objectId={movieTranslation.id}
                type={CONTENT_TYPE_MOVIE_TRANSLATION}
                ratings={movieTranslation.ratings}
                currUserRating={this.state.currUserRating}
                user={user}
                token={token}
                handleRatingUpdateCallback={this.handleRatingUpdateCallback}
              />

              <MovieRelatedBox
                translationId={movieTranslation.id}
                translations={movieTranslation.related_by_tags}
                title={t("Plus de titres similaires")}
              />

              {/* <div className="col-12 mx-auto ">
                <Disqus config={{ url: `https://cinetimes.org/t/${movieTranslation.slug}`, identifier: movieTranslation.id.toString(), title: movieTranslation.title}} />
              </div> */}

              {!isMobile && Math.random() < 0.5 ? (
                <>
                  {/* /t/ - bottom left - before footer  */}
                  <AdComponent
                    client="ca-pub-3093835180445554"
                    slot="1463045123"
                  />
                </>
              ) : (
                <>
                  {/* Multiplex /t/ content bottom */}
                  {/* <AdComponent
                    client="ca-pub-3093835180445554"
                    slot="7025082896"
                    format="autorelaxed"
                  /> */}
                </>
              )}
            </Col>
            <Col
              lg="4"
              className="pl-lg-0 mt-0 px-0 px-md-3"
              id="video_et_description"
            >
              {!isMobile && (
                <>
                  {/* /t/ - sidebar top - desktop  */}
                  <AdComponent
                    client="ca-pub-3093835180445554"
                    slot="6592544140"
                  />
                  <MovieButtonsBox
                    inWatchlist={movieTranslation.inWatchlist}
                    watchlist={watchlist}
                    movieTranslation={movieTranslation}
                    // firstMovie={movieTranslation.movies.length > 0 ? movieTranslation.movies[0] : null}
                    slug={movieTranslation.slug}
                    title={movieTranslation.title}
                    rate={ratingComponent}
                    movie={movie}
                    episodes={movieTranslation.movies}
                  />

                  {/* <AdComponent client="ca-pub-3093835180445554" slot="9635622412" customClasses="mb-3" format="horizontal"/>  */}

                  {/* <HowToSeeBox watchOn={movieTranslation.watchOn} movies={movieTranslation.movies}/> */}

                  {/* [MOVIE_TEMPLATE] Sidebar  */}
                  {/* <AdComponent client="ca-pub-3093835180445554" slot="3675880424" customClasses="w-100 mb-3 sticky-top"/>  */}
                  {/* <div className="mb-3">
                <iframe src="https://rcm-eu.amazon-adsystem.com/e/cm?o=8&p=12&l=ur1&category=prime_video&banner=1AMG5VP0PM1EHJKZG002&f=ifr&linkID=ce7e3d46f2987a72fccb6a2e2defc00f&t=cinetimes0f-21&tracking_id=cinetimes0f-21" width="300" height="250" scrolling="no" border="0" marginwidth="0" style={{border:'none'}} frameborder="0"></iframe>
              </div> */}

                  {/* Adsense /t/ sidebar middle HALF PAGE 300x600 */}
                  {/* <AdsenseHalfPage client="ca-pub-3093835180445554" slot="6102059057"/> */}
                </>
              )}
              <MovieDetailsBox
                title={movieTranslation.title}
                original_title={movie.originalTitle}
                release={movie.release}
                original_country={countryNames}
                runtime={movie.runtime}
                budget={movie.budget}
                box_office={movie.boxOffice}
                genreNames={genreNames}
                tags={movieTranslation.tags}
                prefix={prefix}
              />
              {/* <VideoUploader token={token} moviePK={movie.id} >
                <div className="btn btn-link cursor-pointer" >Ajouter une vidéo </div> <br/>
                <div className="btn btn-link cursor-pointer mb-3" >Ajouter une image </div>
              </VideoUploader> */}
              {/* [MOVIE_TEMPLATE] Sidebar  */}
              {/* <AdComponent client="ca-pub-3093835180445554" slot="3675880424" customClasses="mt-3 w-100 mb-3 sticky-top"/>  */}

              {/* { movieTranslation.recommendations &&
                <div className="text-left">
                  <RelatedMovies related={movieTranslation.recommendations.slice(0, this.state.recommendationNumber)}/>
                  <Observer onChange={this.handleNextRecommendationsLoad} rootMargin="0% 0% 0%">
                    <div className="text-center">{this.state.isRecommendationLoading && <Spinner className="m-auto" color="primary" />}</div>
                  </Observer>
                </div>
              } */}

              {isMobile && (
                <AdComponent
                  client="ca-pub-3093835180445554"
                  slot="3893393492"
                  customClasses=""
                />
              )}
            </Col>
            <History
              poster={movieTranslation.poster}
              slug={movieTranslation.slug}
              title={movieTranslation.title}
            />

            <LogPageView
              event="pageview"
              slug={this.props.location.pathname}
              itemName="MovieTranslation"
              value={null}
              itemId={movieTranslation.id}
            />
          </Row>
        </div>

        {/* <AmazonAd/> */}
      </>
    );
  }
}

export default withTranslation()(MovieTemplate);

// [Log] props:  – {path: "/t/*", navigate: function, location: Object, …} (commons.js, line 95606)
// {path: "/t/*", navigate: function, location: Object, pageResources: Object, *: "hello", …}Object*: "hello"children: undefineddispatch: function(action)location: {href: "http://localhost:8001/t/hello", protocol: "http:", host: "localhost:8001", hostname: "localhost", port: "8001", …}Objectnavigate: function(to, options)pageContext: {matchPath: "/t/*", isCreatedByStatefulCreatePages: true}ObjectpageResources: {component: function, json: {pageContext: {matchPath: "/t/*", isCreatedByStatefulCreatePages: true}}, page: Object}Objectpath: "/t/*"pathContext: {matchPath: "/t/*", isCreatedByStatefulCreatePages: true}Objecttoken: "3415c1279e872da104082ebb0eff065a94d0977e"uri: "/t"user: {pk: 8, username: "Mike", email: "cejotaazamat@gmail.com", first_name: "", last_name: ""}ObjectPrototype Object
